html {
  margin: 0;
  padding: 0;
}

body {
  background-color: black;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

.shader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.lightEffect {
  position: absolute;
  max-width: 0px;
  max-height: 0px;
  border-radius: 50%;
  box-shadow: 0 0 15vw 15vh #ffeab1;
  opacity: 0;
  bottom: 0;
  transition: opacity 2s;
}

#blurEffect {
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  -moz-backdrop-filter: blur(10px);
  z-index: -1;
}

#lightEffectGroup {
  z-index: -2;
}

#lightLeft {
  left: 0;
}

#lightRight {
  right: 0;
}

#principal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: 1;
}

#pContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  padding: 10px;
  gap: 20px;
}

#logo {
  width: 100%;
  opacity: 0;
  transition: 0.5s opacity;
}

#countdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.cdBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0px;
  opacity: 0;
  transition: 0.15s opacity;
}

.cdValues {
  color: #FFF;
  font-size: 40px;
  font-weight: 800;
}

.cdText {
  color: #888;
  font-size: 16px;
  font-weight: 400;
}